<template>
  <v-card elevation="1" class="mt-2" flat v-if="loaded && cartaoConfirmacao">
    <v-row>
      <v-col cols="12">
        <v-card-title class="justify-center">
          Cartão de Confirmação de Inscrição
        </v-card-title>
        <v-card-subtitle class="text-center">
          {{ cartaoConfirmacao.nomeProcesso }}
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card-title class="tituloForm mb-n10">
          Dados do Candidato
        </v-card-title>
      </v-col>
      <v-col cols="6">
        <v-card-subtitle class="font-weight-bold">
          Dados Pessoais
        </v-card-subtitle>
        <v-card-text class="text-body-1">
          <v-row dense>
            <!-- Incluir Nome Social -->
            <v-col cols="auto" class="font-weight-bold">Nome:</v-col>
            <v-col> {{ cartaoConfirmacao.inscricao.dadosPessoais.nome }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">CPF:</v-col>
            <v-col>{{ cartaoConfirmacao.inscricao.dadosPessoais.cpf }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Mãe:</v-col>
            <v-col>{{ cartaoConfirmacao.inscricao.dadosPessoais.mae }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Data de Nascimento:</v-col>
            <v-col>{{ formatDate(cartaoConfirmacao.inscricao.dadosPessoais.dataNascimento) }}</v-col>
          </v-row>
        </v-card-text>
      </v-col>
      <v-col cols="6">
        <v-card-subtitle class="font-weight-bold mt-1">
          Identidade
        </v-card-subtitle>
        <v-card-text class="text-body-1">
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Número :</v-col>
            <v-col> {{ cartaoConfirmacao.inscricao.dadosPessoais.identidade.numero }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Órgão Emissor:</v-col>
            <v-col>{{ cartaoConfirmacao.inscricao.dadosPessoais.identidade.orgaoEmissor }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Estado Emissor:</v-col>
            <v-col>{{ cartaoConfirmacao.inscricao.dadosPessoais.identidade.estadoEmissor.nome }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Data de Emissão:</v-col>
            <v-col>{{ formatDate(cartaoConfirmacao.inscricao.dadosPessoais.identidade.dataEmissao) }}</v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card-title class="tituloForm">
          Dados do Curso
        </v-card-title>
        <v-card-text class="text-body-1">
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Curso:</v-col>
            <v-col> {{ cartaoConfirmacao.inscricao.opcao.nomeOpcao }}</v-col>
          </v-row>
          <div v-if="cartaoConfirmacao.inscricao.inscricaoInstrumentoMusical">
            <v-row dense>
              <v-col cols="auto" class="font-weight-bold">Intrumento:</v-col>
              <v-col> {{ cartaoConfirmacao.inscricao.inscricaoInstrumentoMusical.instrumentoMusical.nome }}</v-col>
            </v-row>
            <v-row dense v-if="cartaoConfirmacao.inscricao.inscricaoInstrumentoMusical.nomeInstrumentoMusical">
              <v-col cols="auto" class="font-weight-bold">Descrição:</v-col>
              <v-col> {{ cartaoConfirmacao.inscricao.inscricaoInstrumentoMusical.nomeInstrumentoMusical }}</v-col>
            </v-row>
          </div>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold"
            >Número da Inscrição:
            </v-col>
            <v-col> {{ cartaoConfirmacao.inscricao.numero }}</v-col>
          </v-row>
        </v-card-text>

      </v-col>
    </v-row>
    <v-row>

      <v-col cols="12">
        <v-card-title class="tituloForm mb-n10">
          Local e Horário da Prova
        </v-card-title>
      </v-col>
      <v-col cols="6">
        <v-card-subtitle class="font-weight-bold mt-1 mb-n5" v-if="cartaoConfirmacao.salaLocalProvaDia2">
          Dia 1
        </v-card-subtitle>
        <v-card-subtitle class="font-weight-bold mt-1">
          Data e Hora
        </v-card-subtitle>
        <v-card-text class="text-body-1">
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Dia:</v-col>
            <v-col> {{ formatDate(cartaoConfirmacao.salaLocalProva.localProva.dataHora) }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Horário:</v-col>
            <v-col> {{ formarTime(cartaoConfirmacao.salaLocalProva.localProva.dataHora) }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-subtitle class="font-weight-bold mt-1">
          Localização
        </v-card-subtitle>
        <v-card-text class="text-body-1">
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Local:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProva.localProva.nome }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Endereço:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProva.localProva.endereco }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Bairro:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProva.localProva.bairro }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Cidade:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProva.localProva.cidade }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Estado:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProva.localProva.estado }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Ponto de Referência:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProva.localProva.referencia }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-subtitle class="font-weight-bold mt-1">
          Sala
        </v-card-subtitle>
        <v-card-text class="text-body-1">
          <v-row dense v-if="cartaoConfirmacao.salaLocalProva.bloco">
            <v-col cols="auto" class="font-weight-bold">Bloco:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProva.bloco }}</v-col>
          </v-row>
          <v-row dense v-if="cartaoConfirmacao.salaLocalProva.andar">
            <v-col cols="auto" class="font-weight-bold">Andar:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProva.andar }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Sala:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProva.sala }}</v-col>
          </v-row>
        </v-card-text>
      </v-col>

      <v-col cols="6" v-if="cartaoConfirmacao.salaLocalProvaDia2">
        <v-card-subtitle class="font-weight-bold mt-1 mb-n5">
          Dia 2
        </v-card-subtitle>
        <v-card-subtitle class="font-weight-bold mt-1">
          Data e Hora
        </v-card-subtitle>
        <v-card-text class="text-body-1">
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Dia:</v-col>
            <v-col> {{ formatDate(cartaoConfirmacao.salaLocalProvaDia2.localProva.dataHora) }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Horário:</v-col>
            <v-col> {{ formarTime(cartaoConfirmacao.salaLocalProvaDia2.localProva.dataHora) }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-subtitle class="font-weight-bold mt-1">
          Localização
        </v-card-subtitle>
        <v-card-text class="text-body-1">
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Local:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProvaDia2.localProva.nome }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Endereço:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProvaDia2.localProva.endereco }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Bairro:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProvaDia2.localProva.bairro }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Cidade:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProvaDia2.localProva.cidade }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Estado:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProvaDia2.localProva.estado }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Ponto de Referência:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProvaDia2.localProva.referencia }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-subtitle class="font-weight-bold mt-1">
          Sala
        </v-card-subtitle>
        <v-card-text class="text-body-1">
          <v-row dense v-if="cartaoConfirmacao.salaLocalProvaDia2.bloco">
            <v-col cols="auto" class="font-weight-bold">Bloco:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProvaDia2.bloco }}</v-col>
          </v-row>
          <v-row dense v-if="cartaoConfirmacao.salaLocalProvaDia2.andar">
            <v-col cols="auto" class="font-weight-bold">Andar:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProvaDia2.andar }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="font-weight-bold">Sala:</v-col>
            <v-col> {{ cartaoConfirmacao.salaLocalProvaDia2.sala }}</v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-if="cartaoConfirmacao.inscricao.necessidadesEspeciais">
          <v-card-title class="tituloForm">
            Necessidades Especiais
          </v-card-title>
          <v-card-text class="text-body-1">
            <v-row
                dense
                v-for="necessidadeEspecial in cartaoConfirmacao.inscricao.necessidadesEspeciais"
                :key="necessidadeEspecial.tipoNecessidadeEspecial.hash"
            >
              <v-col>
                {{
                  necessidadeEspecial.tipoNecessidadeEspecial.codigo === 'OUTRA' ?
                      necessidadeEspecial.tipoNecessidadeEspecial.codigo + ' - ' + necessidadeEspecial.descricaoOutra
                      :
                      necessidadeEspecial.tipoNecessidadeEspecial.codigo + ' - ' + necessidadeEspecial.tipoNecessidadeEspecial.descricao
                }}
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="align-center">
        <div>
          <v-card-title class="tituloForm align-center">
            Observações
          </v-card-title>
          <v-card-text class="text-body-1">
            <v-row dense>
              <v-col> {{ cartaoConfirmacao.inscricao.opcao.observacao }}</v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-col>
    </v-row>
    <v-card-actions fluid class="justify-center">
      <v-btn
          color="success"
          outlined
          @click="recuperarCartaoConfirmacaoPdf"
      >
        Imprimir Cartão de Confirmação
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import cartaoConfirmacaoService from "@/services/cartaoConfirmacao.service";
import luxon from "@/core/luxon";

export default {
  name: "CartaoConfirmacao",

  data() {
    return {
      cartaoConfirmacao: {inscricao: {}},
      loaded: false
    }
  },

  async created() {
    let loader = this.$loading.show();

    await this.recuperarCartaoConfirmacao();

    this.loaded = true;

    loader.hide();
  },

  methods: {
    async recuperarCartaoConfirmacao() {
      await cartaoConfirmacaoService.recuperarCartaoConfirmacao(this.$route.params.oid)
          .then((response) => {
            this.cartaoConfirmacao = response.data;
          });

    },

    formatDate(data) {
      if (data === undefined) {
        return "";
      }
      return luxon.dataConverter(data).toFormat("dd/LL/yyyy");
    },

    formarTime(data) {
      if (data === undefined) {
        return "";
      }
      return luxon.dataConverter(data).toFormat("HH:mm");
    },

    async recuperarCartaoConfirmacaoPdf() {
      let loader = this.$loading.show();

      await cartaoConfirmacaoService
          .recuperarCartaoConfirmacaoPdf(this.$route.params.oid)
          .then(response => {
            const file = new Blob([response.data], {type: "application/pdf"});
            const fileURL = URL.createObjectURL(file);
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.download = "cartaoConfirmacao.pdf";
            fileLink.click();
          });

      loader.hide();
    }
  }

}
</script>

<style scoped>

</style>